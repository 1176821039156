
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        












































































































































































































































































































































































































































































































































































































































































































































































.carousel {
  --slide-gap: 2rem;
  --scrollbar-width: 0.6rem;
  --scrollbar-height: var(--scrollbar-width);
  --scrollbar-border-radius: 0.3rem;
  --scrollbar-foreground-color: #{$c-blue-lightest};
  --scrollbar-background-color: transparent;
  --bullet-size: 1rem;
  --bullet-gap: 2rem;
  --bullet-background-inactive: #{$c-blue-pale};
  --bullet-background-active: #{$c-pink-medium};
  --prevnext-button-size: 4rem;
  --prevnext-button-padding: 0;
  --prevnext-button-border: 2px solid #{$c-anthracite-light};
  --prevnext-button-border-radius: 50%;
  --prevnext-button-background: #{$white};
  --prevnext-color: #{$c-anthracite-light};
  --prevnext-disable-alpha: 0.5;
  --scroller-inline-padding-start: 0;
  --scroller-inline-padding-end: 0;
}

.carousel__scroller {
  @extend %list-nostyle;

  // It is important that the scroller element is positioned as we want it to be the offsetParent of the individual cells.
  // See: https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  gap: var(--slide-gap);
  overflow-x: auto;

  // Apply negative margin to "grows scroller out" of wrapper (if any) and compensate with paddign instead.
  // This will allows carousel items to bleed out of wrapper instead of being clipped.
  margin-inline-start: calc(var(--scroller-inline-padding-start, 0) * -1);
  margin-inline-end: calc(var(--scroller-inline-padding-end, 0) * -1);
  padding-inline-start: var(--scroller-inline-padding-start, 0);
  padding-inline-end: var(--scroller-inline-padding-end, 0);

  &::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: var(--scrollbar-height);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-foreground-color);
    border-radius: var(--scrollbar-border-radius);
  }

  &::-webkit-scrollbar-track {
    // Apply margin to scrollbar track to align it with wrapper
    margin-inline-start: var(--scroller-inline-padding-start, 0);
    margin-inline-end: var(--scroller-inline-padding-end, 0);
    background-color: var(--scrollbar-background-color);
  }

  // Change scrollbar thumb color on hover
  // &:hover {
  //   --scrollbar-foreground-color: #{$c-blue-lightest};
  // }

  // Prevent slides from shrinking
  > * {
    flex-shrink: 0;
  }

  //  Disable pointer events while dragging to prevent accidental text selection
  &.is-dragging {
    cursor: grabbing;

    > * {
      pointer-events: none;
    }
  }
}

.carousel__pagination {
  @extend %list-nostyle;

  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: var(--bullet-gap);
}

.carousel__pagination__button {
  display: block;
  width: var(--bullet-size);
  height: var(--bullet-size);
  padding: 0;
  background-color: var(--bullet-background-inactive);
  border-width: 0;
  border-radius: 50%;

  &.active {
    background-color: var(--bullet-background-active);
  }
}

.carousel__controls {
  display: none;

  @media (hover: hover) and (pointer: fine) {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    gap: 1rem;
  }
}

.carousel__prevnext__icon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--prevnext-color);
}

.carousel__prevnext {
  display: grid;
  place-content: center;
  width: var(--prevnext-button-size);
  height: var(--prevnext-button-size);
  padding: var(--prevnext-button-padding);
  background: var(--prevnext-button-background);
  border: var(--prevnext-button-border);
  border-radius: var(--prevnext-button-border-radius);

  &:hover:not(.disabled) {
    --prevnext-button-background: #{$c-anthracite-light};
    --prevnext-color: #{$white};
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.15;
  }

  &.next {
    .carousel__prevnext__icon {
      transform: rotate(180deg);
    }
  }
}
